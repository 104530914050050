import { Mage } from '../../../../aer-types/types'

export const mages: Mage[] = [
  {
    expansion: 'Legacy',
    name: 'Legacy Green',
    id: 'LegacyGreen',
    abilityName: 'Custom',
    abilityActivation: 'Użyj podczas swojej fazy głównej:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    mageTitle: 'Custom',
    uniqueStarters: [],
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Yellow',
    id: 'LegacyYellow',
    abilityName: 'Custom',
    abilityActivation: 'Użyj podczas swojej fazy głównej:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Orange',
    id: 'LegacyOrange',
    abilityName: 'Custom',
    abilityActivation: 'Użyj podczas swojej fazy głównej:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Blue',
    id: 'LegacyBlue',
    abilityName: 'Custom',
    abilityActivation: 'Użyj podczas swojej fazy głównej:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
]
