import { Mage } from '../../../../aer-types/types'

export const mages: Mage[] = [
  {
    expansion: 'Legacy',
    name: 'Legacy Grün',
    id: 'LegacyGreen',
    abilityName: 'Custom',
    abilityActivation: 'In DEINEM SPIELER-ZUG während <i>Aktionen ausführen</i>:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    mageTitle: 'Custom',
    uniqueStarters: [],
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Gelb',
    id: 'LegacyYellow',
    abilityName: 'Custom',
    abilityActivation: 'In DEINEM SPIELER-ZUG während <i>Aktionen ausführen</i>:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Orange',
    id: 'LegacyOrange',
    abilityName: 'Custom',
    abilityActivation: 'In DEINEM SPIELER-ZUG während <i>Aktionen ausführen</i>:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
  {
    expansion: 'Legacy',
    name: 'Legacy Blau',
    id: 'LegacyBlue',
    abilityName: 'Custom',
    abilityActivation: 'In DEINEM SPIELER-ZUG während <i>Aktionen ausführen</i>:',
    abilityEffect: 'Custom',
    numberOfCharges: 'Custom',
    uniqueStarters: [],
    mageTitle: 'Custom',
  },
]
